import React, {useEffect, useState} from 'react';
import {Container, Typography, Tabs, Tab, Box, Card, CardContent, Button} from '@mui/material';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import GetAppIcon from '@mui/icons-material/GetApp';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
declare var window: any;


const Instructions: React.FC = () => {
    const [value, setValue] = useState(0);
    const [downloadLink, setDownloadLink] = useState('');

    useEffect(() => {
        const fetchVersionInfo = async () => {
            try {
                const response = await fetch('/android_version.json');
                const data = await response.json();
                const currentUrl = window.location.href;
                const url = new URL(currentUrl);
                const baseUrl = url.origin;
                const fullDownloadUrl = `${baseUrl}${data.downloadUrl}`;
                setDownloadLink(fullDownloadUrl);
            } catch (error) {
                console.error('Error fetching version info:', error);
            }
        };

        fetchVersionInfo();

        // Detect the user's OS and set the initial tab value
        const userAgent = window.navigator.userAgent.toLowerCase();
        if (userAgent.includes('android')) {
            setValue(0);
        } else if (/iphone|ipad|ipod/.test(userAgent)) {
            setValue(1);
        }
    }, []);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Container  sx={{  mx: 0 , paddingX: 0 , width: '100%'}}>
            <Typography variant="h6" paddingTop={4}>راهنمای نصب</Typography>
            <Box display="flex" justifyContent="center" my={0}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    centered
                    aria-label="تب‌های دستورالعمل دستگاه"
                    TabIndicatorProps={{ style: { height: '5px' } }} // To make the indicator thicker
                >
                    <Tab
                        icon={<AndroidIcon sx={{ fontSize: '3rem' }} />}
                        label={<Typography variant="h6">اندروید</Typography>}
                        sx={{ minWidth: '160px', padding: '15px', fontSize: '1.2rem' }}
                    />
                    <Tab
                        icon={<AppleIcon sx={{ fontSize: '3rem' }} />}
                        label={<Typography variant="h6">آیفون</Typography>}
                        sx={{ minWidth: '160px', padding: '15px', fontSize: '1.2rem' }}
                    />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}  >
                <Card>
                    <CardContent>
                        <Typography variant="body2" gutterBottom>
                            ۱. اپلیکیشن زیر را دانلود و نصب نمائید
                        </Typography>
                        <Button variant="contained" color="primary"
                                startIcon={<GetAppIcon sx={{marginLeft: 2}}/>}
                                href={downloadLink}
                                download
                                sx={{marginBottom: 4, paddingLeft:4}}
                                >
                            دانلود اپلیکیشن
                        </Button>
                        <Typography variant="body2" gutterBottom>
                            ۲. لینک اشتراک کپی شده را در قسمت لینک اشتراک اپلیکیشن قرار دهید و سپس دکمه ورود را بزنید.
                        </Typography>
                        <img src="/android1.png" style={{width: '100%', height: 'auto'}}/>
                        <Typography variant="body2" gutterBottom color={"#f97910"}>
                            ۳. در صورت عدم اتصال، اشتراک خود را به روز رسانی کنید و تنظیمات دیگری از لیست انتخاب نمائید.
                        </Typography>
                    </CardContent>
                </Card>
            </TabPanel>
            <TabPanel value={value} index={1} >
                <Card>
                    <CardContent>
                        <Typography variant="body2" gutterBottom>
                            ۱. اپلیکیشن V2BOX را از اپ استور دانلود و نصب نمائید
                        </Typography>
                        <Button variant="contained" color="primary"
                                startIcon={<CloudDownloadIcon sx={{marginLeft: 2}}/>}
                                href="https://apps.apple.com/us/app/v2box-v2ray-client/id6446814690"
                                download
                                sx={{marginBottom: 4, paddingLeft:4}}
                        >
                            نصب اپلیکیشن
                        </Button>
                        <Typography variant="body2" gutterBottom>
                            ۲. لینک اشتراک کپی شده را طبق تصویر زیر اضافه کنید.
                        </Typography>
                        <img src="/ios1.jpg" style={{width: '100%', height: 'auto'}}/>

                        <Typography variant="body2" gutterBottom>
                            ۳. اشتراک اضافه شده را انتخاب نمائید.
                        </Typography>
                        <img src="/ios2.jpg" style={{width: '100%', height: 'auto'}}/>

                        <Typography variant="body2" gutterBottom color={"primary"} >
                            ۴. حتما برای دریافت سرویس بهتر حالت Auto Update را فعال نمائید.
                        </Typography>
                        <img src="/ios3.jpg" style={{width: '100%', height: 'auto'}}/>

                        <Typography variant="body2" gutterBottom>
                            ۵. در صفحه HOME دکمه TAB TO CONNECT را بزنید.
                        </Typography>
                        <img src="/ios4.jpg" style={{width: '100%', height: 'auto'}}/>

                        <Typography variant="body2" gutterBottom color={"#f97910"}>
                            ۶. در صورت عدم اتصال، اشتراک خود را به روز رسانی کنید و  تنظیمات دیگری از لیست انتخاب نمائید.
                        </Typography>
                    </CardContent>
                </Card>
            </TabPanel>
        </Container>
    );
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default Instructions;
