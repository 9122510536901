import React, { useEffect, useState } from 'react';
import { Container, CssBaseline, Box, Button, Typography } from '@mui/material';
import SubscriptionLink from './components/SubscriptionLink';
import Instructions from './components/Instructions';

const App: React.FC = () => {
    const [link, setLink] = useState('');

    useEffect(() => {
        const generateLink = () => {
            const currentUrl = window.location.href;
            const url = new URL(currentUrl);
            const baseUrl = "https://" + url.searchParams.get('domain') || 'defaultSubId' ; // Extract the base URL
            const subId = url.searchParams.get('subId') || 'defaultSubId'; // Get subId from query params

            // Detect the user's OS and generate the appropriate link
            const userAgent = window.navigator.userAgent.toLowerCase();
            if (userAgent.includes('android')) {
                setLink(`${baseUrl}/sub/android/${subId}`);
            } else if (/iphone|ipad|ipod/.test(userAgent)) {
                setLink(`${baseUrl}/sub/apple/${subId}`);
            } else {
                setLink(`${baseUrl}/sub/${subId}`);
            }
        };

        generateLink();
    }, []);

    const telegramMessage = `سلام من برای نصب و راه اندازی نیاز به کمک دارم ${link}`;
    const telegramUrl = `https://t.me/${encodeURIComponent('maxvpnservice')}?text=${encodeURIComponent(telegramMessage)}`;

    return (
        <Container dir="rtl">
            <CssBaseline />
            <SubscriptionLink />
            <Instructions />
            <Box sx={{ marginTop: 4, textAlign: 'center' }}>
                <Typography variant="h6" gutterBottom>
                    اگر نیاز به راهنمایی بیشتر دارید و یا با مشکل برخورد کردید با ما تماس بگیرید
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    href={telegramUrl}
                    target="_blank"
                    sx={{ marginTop: 2 , marginBottom:4}}
                >
                    تماس از طریق تلگرام
                </Button>
            </Box>
        </Container>
    );
};

export default App;
