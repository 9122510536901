import React, { useEffect, useState } from 'react';
import { Button, Tooltip, Box, tooltipClasses, styled, TooltipProps, TextField } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';

declare var window: any;

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#f97910',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f97910',
        fontSize: '1rem'
    },
}));

const CopyButton: React.FC = () => {
    const [link, setLink] = useState('');
    const [tooltipOpen, setTooltipOpen] = useState(false);

    useEffect(() => {
        const generateLink = () => {
            const currentUrl = window.location.href;
            const url = new URL(currentUrl);
            const baseUrl = "https://"+url.searchParams.get('domain') || 'defaultSubId' ;
            const subId = url.searchParams.get('subId') || 'defaultSubId'; // Get subId from query params

            // Detect the user's OS and generate the appropriate link
            const userAgent = window.navigator.userAgent.toLowerCase();
            if (userAgent.includes('android')) {
                setLink(`${baseUrl}/sub/android/${subId}`);
            } else if (/iphone|ipad|ipod/.test(userAgent)) {
                setLink(`${baseUrl}/sub/apple/${subId}`);
            } else {
                setLink(`${baseUrl}/sub/${subId}`);
            }
        };

        generateLink();
    }, []);

    const showToolTip = () => {
        setTooltipOpen(true);
        setTimeout(() => {
            setTooltipOpen(false);
        }, 3000); // Tooltip stays open for 3 seconds
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%' }}>
            <CopyToClipboard text={link} onCopy={showToolTip}>
                <Button
                    variant="outlined"
                    color="secondary"
                    sx={{
                        textTransform: 'none',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        color: 'gray',
                        whiteSpace: 'pre-wrap', // Allows text to wrap to multiple lines
                        wordBreak: 'break-word', // Ensures long words break to fit
                        maxWidth: '100%', // Ensures button does not exceed container width
                        padding: 1 // Optional padding to adjust the button size
                    }}
                >
                    {link}
                </Button>
            </CopyToClipboard>
            <CopyToClipboard text={link} onCopy={showToolTip}>
                <BootstrapTooltip
                    title="لینک اشتراک شما کپی شد"
                    open={tooltipOpen}
                    onClose={() => setTooltipOpen(false)}
                    disableHoverListener
                    arrow
                >
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<ContentCopyIcon sx={{ marginLeft: 1 }} />}
                        sx={{ paddingLeft: 3 }}
                    >
                        کپی
                    </Button>
                </BootstrapTooltip>
            </CopyToClipboard>
        </Box>
    );
};

export default CopyButton;
